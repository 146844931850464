@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-bold.woff2") format("woff2"),
    url("/assets/fonts/opensans-bold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-bolditalic.woff2") format("woff2"),
    url("/assets/fonts/opensans-bolditalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-extrabold.woff2") format("woff2"),
    url("/assets/fonts/opensans-extrabold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-extrabolditalic.woff2") format("woff2"),
    url("/assets/fonts/opensans-extrabolditalic.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-italic.woff2") format("woff2"),
    url("/assets/fonts/opensans-italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-light.woff2") format("woff2"),
    url("/assets/fonts/opensans-light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-lightitalic.woff2") format("woff2"),
    url("/assets/fonts/opensans-lightitalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-regular.woff2") format("woff2"),
    url("/assets/fonts/opensans-regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-semibold.woff2") format("woff2"),
    url("/assets/fonts/opensans-semibold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("/assets/fonts/opensans-semibolditalic.woff2") format("woff2"),
    url("/assets/fonts/opensans-semibolditalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
}
