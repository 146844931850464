@import "reset";
@import "fonts";

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  padding: 0;
  margin: 0;
  font-family: "Open Sans";
}

.two-cols {
  display: flex;
}

.graph-container,
.editor-container {
  height: 100vh;
  width: 50%;
}
.editor-container {
  overflow: auto;
}
.graph-container {
  background-color: #dddddd;
  position: relative;
  overflow: hidden;
}

.graph-menu {
  position: absolute;
  height: 40px;
  width: 100%;
  background-color: #666666;

  .node-tools {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 10px;
  }
  .zoom-menu {
    float: right;
  }

  .main-menu {
    font-size: 12px;
    li {
      display: inline-block;
      padding: 10px 20px;
      position: relative;
      cursor: pointer;
    }
    > li > ul {
      position: absolute;
      top: 100%;
      left: 0;
      background-color: #ccc;
      display: none;
      li {
        display: block;
        padding: 20px;
        white-space: nowrap;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    > li:hover {
      background-color: #ccc;
      > ul {
        display: block;
      }
    }
  }
}

.graph {
  width: 100%;
  height: 100%;
}

.overlay-bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 500;
  .overlay-container {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80vw;
    height: 80vh;
    transform: translate(-50%, -50%);
    background-color: white;
    box-sizing: border-box;
    padding: 50px;
  }
}

.ui-body-copy {
  line-height: 1.5;
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 20px;
    margin-bottom: 1em;
  }
  p {
    margin-bottom: 1em;
  }
}

.intro-overlay {
  .logo {
    width: 50px;
    height: 50px;
    float: left;
    margin-right: 10px;
  }
  .one-liner {
    clear: both;
    font-size: 20px;
    font-weight: bold;
  }
  .pages {
    position: absolute;
    top: 40px;
    left: 40px;
    bottom: 100px;
    right: 40px;
    overflow: hidden;

    .page-slider {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      transition-duration: 0.5s;
    }
    .page {
      height: 100%;
      padding: 10px;
      width: (100% / 3);
      float: left;
      box-sizing: border-box;
      overflow-y: scroll;
    }
    .page-2-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .section {
        width: 45%;
        img {
          width: 50%;
          margin-bottom: 1em;
        }
      }
    }
  }
  .pagination {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    text-align: center;
    .page {
      width: 12px;
      height: 12px;
      background-color: #ccc;
      display: inline-block;
      overflow: hidden;
      border-radius: 100%;
      margin-right: 20px;
      transition-duration: 0.5s;
      color: rgba(0, 0, 0, 0);
      cursor: pointer;
    }
    .current {
      background-color: #666666;
    }
  }
  .intro-nav {
    position: absolute;
    bottom: 40px;
    &.left {
      left: 20px;
    }
    &.right {
      right: 20px;
    }
    .button {
      display: inline-block;
      padding: 10px 10px;
      background-color: #ccc;
      border-radius: 5px;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
  }
}

.workspace-list {
  button {
    margin-left: 20px;
  }
  .workspace {
    border: 1px solid black;
    padding: 20px;
    margin-top: 20px;
  }
  .workspace-icon {
    width: 30px;
    height: 30px;
    transform: translate(0px, 10px);
  }
  .document-list {
    padding-top: 20px;
    padding-left: 50px;
  }
}

.data-export-options {
  margin-top: 50px;
}
