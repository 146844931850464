.md-editor {
  background-color: #eee;
  padding: 20px;

  font-family: "Open Sans" !important;

  h1,
  h2,
  h3,
  h4,
  ul,
  ol,
  blockquote {
    margin-bottom: 1em;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 0.5em;
  }
  h2 {
    font-size: 24px;
    margin-bottom: 0.5em;
  }
  em {
    font-style: italic;
  }

  p {
    min-height: 1em;
  }

  [class*="StyledEditor"] {
    line-height: 1.5;
  }

  [class*="BlockToolbar__Bar"] {
    box-sizing: border-box;
    padding-left: 10px;
    &:before,
    &:after {
      display: none;
    }
  }
}

.ProseMirror {
  border: 1px solid gray;
  .heading-name:nth-child(1) {
    display: none;
  }
}
