.document-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: white;
    line-height: 1.35;

    .document-wrapper {
        padding: 50px;
        max-width: 1024px;
        box-sizing: border-box;
        margin: auto;
    }

    .document-node {
        &:last-child {
            hr {
                display: none;
            }
        }
        .node-title {
            font-weight: bold;
            font-size: 2em;
        }
    }

    .md-editor {
        border: 0;
        padding: 0;
        background-color: white;
    }
    [class*="Heading__Anchor"],
    [class*="Heading__CollapseToggle"] {
        display: none;
    }
    [class*="Heading__Wrapper"] {
        margin-left: 24px;
    }

    hr {
        border: 0;
        border-top: 1px solid black;
        width: 50%;
        margin: 50px auto;
    }
}
