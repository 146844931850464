.editor {
  padding: 20px;
  .node-name {
    border: 0;
    padding: 10px;
    margin: 0;
    border-bottom: 2px solid black;
    width: 100%;
    font-size: 20px;
    transition-duration: 0.5s;
    box-sizing: border-box;
    &:focus {
      background-color: #d9d9d9;
    }
  }
  .parent {
    padding: 20px 0;
  }
}
